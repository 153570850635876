import PropTypes from 'prop-types'
import { variants, Alignment } from '@resident-advisor/design-system'
import LinkButton from '@/components/generic/link-button'
import { hrefAsPropTypes } from '@/components/generic/link'

const ViewMoreButton = ({ href, children, ...linkProps }) => (
  <Alignment justifyContent="center" pt={3}>
    <LinkButton variant={variants.button.secondary} href={href} {...linkProps}>
      {children}
    </LinkButton>
  </Alignment>
)

ViewMoreButton.propTypes = {
  href: hrefAsPropTypes,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
}

export default ViewMoreButton
