import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroller'
import getConfig from 'next/config'
import { Box } from '@resident-advisor/design-system'
import { useIntl } from 'react-intl'
import { useRouter } from 'next/router'
import dict from '@/messages/dict'
import ViewMoreButton from '@/components/generic/view-more-button'
import QueryResultHandler, {
  DefaultLoadingSpinner,
} from '@/components/generic/query-result-handler'
import type { QueryResultHandlerProps } from '../query-result-handler/QueryResultHandler'

const {
  publicRuntimeConfig: { INFINITE_SCROLL_CONFIG },
} = getConfig()

const InfiniteScrollQueryResultHandler = ({
  fetchNextPage,
  finishedPaginating,
  fetchingNextPage,
  loading,
  nextPageHref,
  backgroundColor,
  ...props
}: InfiniteScrollQueryResultHandlerProps) => {
  const intl = useIntl()
  const { asPath } = useRouter()

  const showViewMoreButton =
    !fetchingNextPage && !finishedPaginating && !loading

  return (
    <>
      <InfiniteScroll
        loadMore={fetchNextPage}
        initialLoad={false}
        hasMore={!finishedPaginating}
        threshold={parseInt(
          INFINITE_SCROLL_CONFIG.INFINITE_SCROLL_LOADMORE_THRESHOLD_PX,
          10
        )}
      >
        <QueryResultHandler {...props} loading={loading} />
      </InfiniteScroll>
      {fetchingNextPage && (
        <DefaultLoadingSpinner
          useDelay={false}
          backgroundColor={backgroundColor}
        />
      )}
      {showViewMoreButton && (
        <Box bg={backgroundColor} pb={3}>
          <ViewMoreButton
            onClick={(evt) => {
              // Prevent the browser following the href - it's just there for SEO
              evt.preventDefault()
              evt.stopPropagation()
              fetchNextPage(true)
            }}
            href={nextPageHref || asPath}
          >
            {intl.formatMessage(dict.loadMore)}
          </ViewMoreButton>
        </Box>
      )}
    </>
  )
}

interface InfiniteScrollQueryResultHandlerProps
  extends QueryResultHandlerProps {
  fetchNextPage: (value: boolean) => unknown
  finishedPaginating?: boolean
  fetchingNextPage?: boolean
  nextPageHref?: string
  backgroundColor?: string
  hideNewsType?: boolean
  groupDataInterval?: string
  firstDate?: number | Date
  selectedYear?: number
  dateFormat?: string
  formatTypeUrl?: (type: string) => string
  hideAd?: boolean
}

InfiniteScrollQueryResultHandler.propTypes = {
  backgroundColor: PropTypes.string,
  bg: PropTypes.string,
  fetchNextPage: PropTypes.func.isRequired,
  finishedPaginating: PropTypes.bool.isRequired,
  fetchingNextPage: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  nextPageHref: PropTypes.string,
}

export default InfiniteScrollQueryResultHandler
