import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import {
  Alignment,
  Text,
  Icon,
  ArrowLeftIcon,
  ArrowRightIcon,
  variants,
} from '@resident-advisor/design-system'
import Link, { hrefAsPropTypes } from '@/components/generic/link'
import dict from '@/messages/dict'

const Label = ({ children }) => (
  <Text color="secondary" variant={variants.text.label}>
    {children}
  </Text>
)

Label.propTypes = {
  children: PropTypes.node.isRequired,
}

const NavigationItemWrapper = ({ children, isHidden, ...props }) => (
  <Alignment
    justifyContent="space-between"
    py={{ s: 3, m: 5 }}
    flexBasis="50%"
    css={{ visibility: isHidden ? 'hidden' : 'visible' }}
    {...props}
  >
    {children}
  </Alignment>
)

NavigationItemWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  isHidden: PropTypes.bool,
}

const NavigationItemPrevious = ({ title, href, as, isHidden, navLabel }) => {
  const { formatMessage } = useIntl()

  const label = navLabel || formatMessage(dict.previous)
  return (
    <NavigationItemWrapper
      pr={{ s: 3, m: 5 }}
      isHidden={isHidden}
      flexDirection="column"
      alignItems={{ s: 'flex-start', m: 'flex-end' }}
    >
      <Label>{label}</Label>
      <Alignment
        flexDirection={{ s: 'column', m: 'row-reverse' }}
        alignItems={{ s: 'flex-start', m: 'center' }}
        justifyContent="space-between"
        width="100%"
      >
        <Link
          display="block"
          variant={variants.text.heading.l}
          href={href}
          as={as}
          pb={{ s: 4, m: 0 }}
        >
          {title}
        </Link>
        <Icon Component={ArrowLeftIcon} color="primary" />
      </Alignment>
    </NavigationItemWrapper>
  )
}

const NavigationItemNext = ({ title, href, as, isHidden, navLabel }) => {
  const { formatMessage } = useIntl()

  const label = navLabel || formatMessage(dict.next)

  return (
    <NavigationItemWrapper
      pl={{ s: 3, m: 5 }}
      isHidden={isHidden}
      flexDirection="column"
      alignItems={{ s: 'flex-end', m: 'flex-start' }}
    >
      <Label>{label}</Label>
      <Alignment
        flexDirection={{ s: 'column', m: 'row' }}
        alignItems={{ s: 'flex-end', m: 'center' }}
        justifyContent="space-between"
        width="100%"
      >
        <Link
          display="block"
          variant={variants.text.heading.l}
          href={href}
          as={as}
          pb={{ s: 4, m: 0 }}
        >
          {title}
        </Link>
        <Icon Component={ArrowRightIcon} color="primary" />
      </Alignment>
    </NavigationItemWrapper>
  )
}

const NavigationItem = ({ type, ...props }) => {
  return type === 'previous' ? (
    <NavigationItemPrevious {...props} />
  ) : (
    <NavigationItemNext {...props} />
  )
}

NavigationItem.propTypes = {
  type: PropTypes.oneOf(['previous', 'next']),
}

const DirectionalPropTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),

  href: hrefAsPropTypes,
  as: hrefAsPropTypes,
  isHidden: PropTypes.bool,
  navLabel: PropTypes.string,
}

NavigationItemPrevious.propTypes = DirectionalPropTypes
NavigationItemNext.propTypes = DirectionalPropTypes

export default NavigationItem
